$(document).ready(function() {
  AOS.init();



  var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
  });

  $("nav .navbar-nav  a.nav-link").on("click", function() {
    
    var si = $("#boton").hasClass("collapsed");
    if (si == false) {
      var scroll = new SmoothScroll('a[href*="#"]', {
        offset: 65
      });
      $(si).addClass("collapsed");
      $("#navegacion").removeClass("show");
    } else {
      $(si).removeClass("collapsed");
    }
  });

});
